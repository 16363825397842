/**
* Template Name: Arsha - v4.9.1
* Template URL: https://bootstrapmade.com/arsha-free-bootstrap-html-template-corporate/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
(function() {
  "use strict";

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all)
    if (selectEl) {
      if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener))
      } else {
        selectEl.addEventListener(type, listener)
      }
    }
  }

  /**
   * Easy on scroll event listener 
   */
  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }

  /**
   * Navbar links active state on scroll
   */
  let navbarlinks = select('#navbar .scrollto', true)
  const navbarlinksActive = () => {
    let position = window.scrollY + 200
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return
      let section = select(navbarlink.hash)
      if (!section) return
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active')
      } else {
        navbarlink.classList.remove('active')
      }
    })
  }
  window.addEventListener('load', navbarlinksActive)
  onscroll(document, navbarlinksActive)

  /**
   * Scrolls to an element with header offset
   */
  const scrollto = (el) => {
    let header = select('#header')
    let offset = header.offsetHeight

    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }

  /**
   * Toggle .header-scrolled class to #header when page is scrolled
   */
  let selectHeader = select('#header')
  if (selectHeader) {
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add('header-scrolled')
      } else {
        selectHeader.classList.remove('header-scrolled')
      }
    }
    window.addEventListener('load', headerScrolled)
    onscroll(document, headerScrolled)
  }

  /**
   * Back to top button
   */
  let backtotop = select('.back-to-top')
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add('active')
      } else {
        backtotop.classList.remove('active')
      }
    }
    window.addEventListener('load', toggleBacktotop)
    onscroll(document, toggleBacktotop)
  }

  /**
   * Mobile nav toggle
   */
  on('click', '.mobile-nav-toggle', function(e) {
    select('#navbar').classList.toggle('navbar-mobile')
    this.classList.toggle('bi-list')
    this.classList.toggle('bi-x')
  })

  /**
   * Mobile nav dropdowns activate
   */
  on('click', '.navbar .dropdown > a', function(e) {
    if (select('#navbar').classList.contains('navbar-mobile')) {
      e.preventDefault()
      this.nextElementSibling.classList.toggle('dropdown-active')
    }
  }, true)

  /**
   * Scrool with ofset on links with a class name .scrollto
   */
  on('click', '.scrollto', function(e) {
    if (select(this.hash)) {
      e.preventDefault()

      let navbar = select('#navbar')
      if (navbar.classList.contains('navbar-mobile')) {
        navbar.classList.remove('navbar-mobile')
        let navbarToggle = select('.mobile-nav-toggle')
        navbarToggle.classList.toggle('bi-list')
        navbarToggle.classList.toggle('bi-x')
      }
      scrollto(this.hash)
    }
  }, true)

  /**
   * Scroll with ofset on page load with hash links in the url
   */
  window.addEventListener('load', () => {
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash)
      }
    }
  });

  /**
   * Preloader
   */
  let preloader = select('#preloader');
  if (preloader) {
    window.addEventListener('load', () => {
      preloader.remove()
    });
  }

  /**
   * Initiate  glightbox 
   */
  const glightbox = GLightbox({
    selector: '.glightbox'
  });

  /**
   * Skills animation
   */
  let skilsContent = select('.skills-content');
  if (skilsContent) {
    new Waypoint({
      element: skilsContent,
      offset: '80%',
      handler: function(direction) {
        let progress = select('.progress .progress-bar', true);
        progress.forEach((el) => {
          el.style.width = el.getAttribute('aria-valuenow') + '%'
        });
      }
    })
  }

  /**
   * Porfolio isotope and filter
   */
  window.addEventListener('load', () => {
    let portfolioContainer = select('.portfolio-container');
    if (portfolioContainer) {
      let portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item'
      });

      let portfolioFilters = select('#portfolio-flters li', true);

      on('click', '#portfolio-flters li', function(e) {
        e.preventDefault();
        portfolioFilters.forEach(function(el) {
          el.classList.remove('filter-active');
        });
        this.classList.add('filter-active');

        portfolioIsotope.arrange({
          filter: this.getAttribute('data-filter')
        });
        portfolioIsotope.on('arrangeComplete', function() {
          AOS.refresh()
        });
      }, true);
    }

  });

  /**
   * Initiate portfolio lightbox 
   */
  const portfolioLightbox = GLightbox({
    selector: '.portfolio-lightbox'
  });

  /**
   * Portfolio details slider
   */
  new Swiper('.portfolio-details-slider', {
    speed: 400,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });


  
/**simulator */

    
       document.addEventListener('DOMContentLoaded', () => {
    const steps = Array.from(document.querySelectorAll('.stepper-item'));
    const formSteps = Array.from(document.querySelectorAll('.form-step'));
    let currentStep = 0;

    document.querySelectorAll('.next-btn').forEach(button => {
        button.addEventListener('click', () => {
            if (currentStep < steps.length - 1) {
                steps[currentStep].classList.add('completed'); // Mark current step as completed
                formSteps[currentStep].classList.remove('active');
                currentStep++;
                steps[currentStep].classList.add('active'); // Mark next step as active
                formSteps[currentStep].classList.add('active');
            }
        });
    });
    document.getElementById('loanForm').addEventListener('submit', function(event) {
      event.preventDefault();
      calculateEligibility();
  
      // Hide the form
      this.style.display = 'none';
  
      // Show the result
      var resultElement = document.getElementById('result');
      resultElement.style.display = 'block';
  
      // Index of the third step
      const thirdStepIndex = 2;
  
      // Update classes for each step
      steps.forEach((step, index) => {
          if (index < thirdStepIndex) {
              step.classList.add('completed');
              step.classList.remove('active');
          } else if (index === thirdStepIndex) {
              step.classList.add('completed', 'active');
          } else {
              step.classList.remove('completed', 'active');
          }
      });
  
      // Ensure the form steps are updated as well
      formSteps.forEach((formStep, index) => {
          if (index <= thirdStepIndex) {
              formStep.classList.add('active');
          } else {
              formStep.classList.remove('active');
          }
      });
  
      // Update the current step
      currentStep = thirdStepIndex;
  
      // Optionally, you can add any logic here to display a message, redirect the user,
      // or perform other actions upon successful form submission.
  });
  
  

    document.querySelectorAll('.prev-btn').forEach(button => {
        button.addEventListener('click', () => {
            if (currentStep > 0) {
                steps[currentStep].classList.remove('active'); // Remove active from current step
                formSteps[currentStep].classList.remove('active');
                currentStep--;
                steps[currentStep].classList.remove('completed'); // Remove completed from the previous step
                steps[currentStep].classList.add('active'); // Mark previous step as active again
                formSteps[currentStep].classList.add('active');
            }
        });
    });
    
    function calculateEligibility() {
      var statutProfessionnel = document.getElementById('statutProfessionnel').value;
      var creditEncore = document.getElementById('creditEncore').checked;
      var chequeImpaye = document.getElementById('chequeImpaye').checked;
      var income = parseFloat(document.getElementById('income').value);
      var creditType = document.getElementById('creditType').value;
      var loanAmount = parseFloat(document.getElementById('loanAmount').value);
      var duration = parseInt(document.getElementById('duration').value);
  
      var eligible = true; 
      if (statutProfessionnel == 'Contractuel' || creditEncore || chequeImpaye) {
          eligible = false;
      }
  
      var monthlyPayment = calculateMonthlyPayment(loanAmount, duration);
      var maxPayment = income * 0.40; // 40% of the income
  
      if (eligible) {
            document.getElementById('result').innerHTML = "أنت مؤهل لهذا القرض. قسطك الشهري: " + monthlyPayment.toFixed(2) + " دينار تونسي" + '<br><button id="resetButton" class="btn btn-custom"><i class="bi bi-arrow-repeat"></i>إعادة</button>';
        } else {
            document.getElementById('result').innerHTML = "يمكنك الاتصال بنا لتثبت من أهليتك في الحصول على القرض" + '<br><button id="resetButton" class="btn btn-custom"><i class="bi bi-arrow-repeat"></i>إعادة</button>';
        }

        document.getElementById('resetButton').addEventListener('click', resetForm);
    }

    function resetForm() {
        // Reset the form and steps to their initial states
        document.getElementById('loanForm').reset();
        document.getElementById('loanForm').style.display = 'block';
        document.getElementById('result').style.display = 'none';
        currentStep = 0;
        steps.forEach((step, index) => {
            step.classList.remove('completed', 'active');
            if (index === 0) step.classList.add('active');
        });
        formSteps.forEach((formStep, index) => {
            formStep.classList.remove('active');
            if (index === 0) formStep.classList.add('active');
        });
        // Reset any additional elements like the duration slider and result display if needed
        document.getElementById('durationValue').innerText = document.getElementById('duration').value;
    }
        document.getElementById('creditType').addEventListener('change', function() {
          var creditType = this.value;
          var durationSlider = document.getElementById('duration');
          var durationValueDisplay = document.getElementById('durationValue');
          
          switch (creditType) {
              case 'personal':
                  durationSlider.min = 12;
                  durationSlider.max = 60;
                  break;
              case 'auto':
                  durationSlider.min = 12;
                  durationSlider.max = 84;
                  break;
              case 'mortgage':
                  durationSlider.min = 12;
                  durationSlider.max = 300;
                  break;
              case 'fund':
                  durationSlider.min = 12;
                  durationSlider.max = 36;
                  break;
          }
          durationSlider.value = durationSlider.min; // Reset to minimum value
          durationValueDisplay.innerText = durationSlider.value;
      });
      document.getElementById('duration').addEventListener('input', function() {
        document.getElementById('durationValue').innerText = this.value;
    });
          
        function calculateMonthlyPayment(M, n) {
            var m = (M * 0.11 / 12) / (1 - Math.pow((1 + 0.11 / 12), -n));
            return m;
        }
    
        document.getElementById('loanForm').addEventListener('submit', function(event) {
        event.preventDefault();
        calculateEligibility();
    });
});


  /**
   * Animation on scroll
   */
  window.addEventListener('load', () => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
  });

})()

